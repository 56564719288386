import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Button from "../../components/design/buttons/Button"
import Footer from "../../components/footer/footer"
import Header from "../../components/header/header"
import DownloadSection from "./components/download"
import Hero from "./components/hero"
import Map from "./components/map"
import Midle from "./components/midle"
import MidleMobile from "./components/midle_mibile"
import Packs from "./components/packs"
import Partners from "./components/partners"
import Slider from "./components/slider"
import Topics from "./components/topics"
import './landing.css'
import { Modal } from "react-bootstrap"
import { GlassPanel } from "../professionals/graph/Model"
import { Tilt } from "react-tilt"
import { useNavigate } from "react-router-dom"
import Animate from "../about/Animate"
import React from "react"
import Ads from "./components/ads"
import { cursorTo } from "readline"
export const LandingScreen = () => {
    const {t, i18n} = useTranslation();
    const [inners, setInners] = useState<any>([
    ])
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        setInners([[
                {
                    title: t("landing.section3.item1.title"),
                    content: t("landing.section3.item1.sub_title"),
                    image: "./media/midle/111.png"
            },
                {
                    title: t("landing.section3.item2.title"),
                    content: t("landing.section3.item2.sub_title"),
                    image: "./media/midle/222.png"
                },
                {
                    title: t("landing.section3.item3.title"),
                    content: t("landing.section3.item3.sub_title"),
                    image: "./media/midle/333.png"
                },
            ],[
                {
                    title: t("landing.section3.item4.title"),
                    content: t("landing.section3.item4.sub_title"),
                    image: "./media/slider/1.png"
                },
                {
                    title: t("landing.section3.item5.title"),
                    content: t("landing.section3.item5.sub_title"),
                    image: "./media/slider/1.png"
                },
                {
                    title: t("landing.section3.item6.title"),
                    content: t("landing.section3.item6.sub_title"),
                    image: "./media/slider/1.png"
                },
                {
                    title: t("landing.section3.item7.title"),
                    content: t("landing.section3.item7.sub_title"),
                    image: "./media/slider/1.png"
                },
                {
                    title: t("landing.section3.item8.title"),
                    content: t("landing.section3.item8.sub_title"),
                    image: "./media/slider/1.png"
                },
                {
                    title: t("landing.section3.item9.title"),
                    content: t("landing.section3.item9.sub_title"),
                    image: "./media/slider/1.png"
                },

            ]])
    }, [i18n.language])
    const [openDownload, setOpenDownload] = React.useState(false);

    return (
        
        <div className="esims_container" style={{backgroundImage: "url(./media/hero/bg2.png)", backgroundColor:"#F7F7F7" }}>
            {/* <div className="background_animated">
   <span className="random-size-5"></span>
   <span className="random-size-4"></span>
   <span className="random-size-3"></span>
   <span className="random-size-1"></span>
   <span className="random-size-6"></span>
   <span className="random-size-3"></span>
   <span className="random-size-5"></span>
   <span className="random-size-2"></span>
   <span className="random-size-6"></span>
   <span className="random-size-1"></span>
   <span className="random-size-2"></span>
   <span className="random-size-4"></span>
</div> */}
            <div className="container">
            <Header />
            </div>
            <Animate delay={0.3}  >
                    <div className='offer_bg p-2'>
                        <div className="container offer_bg p-0">
                        <div onClick={() => {
                            setOpenDownload(true)
                        }} className='d-flex align-items-center justify-content-center' style={{cursor:"pointer"}}>
                            {/* <img src="./media/logos/salamm.png" alt="" width={60} height={60}  /> */}
                            <span className=' offer_title'>
                                {t("landing.offer.title")}
                            </span>
                            <span className='offer_text'>
                                {t("landing.offer.text")}
                            </span>

                            <div style={{scale:"0.5", position: "relative", top: "-10px"}} className='d-none d-lg-block'>

                            <svg  width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M42.0091 28.6335L53.5045 44L65 28.6335C58.1629 23.7888 48.8371 23.7888 42 28.6335" fill="#FAFAFA"/>
                                <path opacity="0.3" d="M5.00674 11.6773L13.5034 23L22 11.6773C16.9465 8.10756 10.0535 8.10756 5 11.6773" fill="#FAFAFA"/>
                            </svg>
                            </div>

                        </div>
                        {/* <Button inner={t("landing.offer.btn")} color="primary mt-3 mt-lg-0" */}
                         {/* size='sm'
                        onClick={() => {
                            setOpenDownload(true)
                        }}/> */}
                        </div>
                    </div>
                </Animate>
            <div className="container">
            <Hero />
            <Ads />
            <Topics />
            </div>
            {/* <div className=" d-lg-block midle_bg">
                <div className="container p-0">
                {
                    inners.length > 0 && 
                            <Slider 
                                        inners={inners}
                                        seperator={<div className="slider-seperator">
                                            <div className="slider-seperator__title">
                                                <h1>Choose your plan</h1>
                                            </div>
                                            <div className="slider-seperator__content">
                                                <p>Choose your plan and get your eSIM</p>
                                            </div>
                                        </div>}
                        
                                    
                                    />
                }
                </div>
            </div> */}
            <div className="d-block d-lg-none">
            {/* <MidleMobile /> */}
            </div>
            <Packs />
            <div className=" mb-4 container">
            <Partners />
            </div>
            {/* <Map /> */}
            <DownloadSection />
            {/* <ProsSection /> */}
            <Footer />
            <div className="d-block d-lg-none footer_all d-wol" style={{color: "#FFFFFF",fontSize: 12,}} >
            {/* <div className="py-4 container d-flex d-lg-none justify-content-between align-items-center">
           
                <div className="d-flex align-items-center">
                <div style={
                    {
                        width: 48,
                        height: 48,
                        borderRadius: 8,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }
                }>
                </div>
                <div className="mx-3 d-none">
                    <strong>Tygo</strong>
                    <p className="m-0">All Communication Services .</p>
                </div>
                </div>
                <Button inner={t("landing.btn")}
                 isrounded={true} color=" p-4 primary download_btn" onClick={
                    () => {
                        handleShow()
                    }
                }
                />
            </div> */}
            </div>
            <Modal show={show} onHide={handleClose}>
        <Modal.Body>
        {/* width: 375px;
    margin: auto;
    margin-bottom: 25px;
    text-align: end !important;
    font-weight: 300; */}
                        <h1 className='mb-2 mt-2 mt-lg-3 mb-lg-5 text-center
                        '>{t("footer.title")}</h1>
                        <p className='my-2 my-lg-5 text-center'>
                            {t("footer.sub_title")}
                        </p>
                        <div className="d-flex justify-content-center">
          <div className='m-2'> 
          <a href="https://apps.apple.com/qa/app/tygo/id1619973559?platform=iphone](https://apps.apple.com/qa/app/tygo/id1619973559?platform=iphone)" target="_blank">
          <img src="/media/buttons/apple.svg" width={124} />
          </a>
          </div>
          <div className='m-2'>
          <a href='https://play.google.com/store/apps/details?id=app.tygo.sa' target="_blank">
          <img src="/media/buttons/google.svg" width={124} />
          </a>
          </div>
                </div>
        </Modal.Body>
      </Modal>
      <Modal onHide={() => setOpenDownload(false)} show={openDownload} size='lg' centered>
        <Modal.Body>
                    
                    <h1 className='mb-2 mt-2 mt-lg-3 mb-lg-5 text-center
                    '>{t("footer.title")}</h1>
                    <p className='my-2 my-lg-5 text-center'>
                        {t("footer.sub_title")}
                    </p>
                    <div className="d-flex justify-content-center">
                <div className='m-2'> 
                <a href="https://apps.apple.com/qa/app/tygo/id1619973559?platform=iphone](https://apps.apple.com/qa/app/tygo/id1619973559?platform=iphone)" target="_blank">
                <img src="/media/buttons/apple.svg" width={124} />
                </a>
                </div>
                <div className='m-2'>
                <a href='https://play.google.com/store/apps/details?id=app.tygo.sa' target="_blank">
                <img src="/media/buttons/google.svg" width={124} />
                </a>
                </div>
                        </div>
                </Modal.Body>
        </Modal>
        </div>
    )
}


const ProsSection = () => {
    const navigate = useNavigate();
    const { t , i18n } = useTranslation();
    return (
        <div className="pros_section">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-6 order-2 order-lg-1">
                        <div className="pros_section__left  ">
                            <h1 style={{fontSize:"50px"}}>Tygo Business</h1>
                            <div className="row mt-4">
                            <div className="col-12 col-lg-6 mb-3">
                                <Tilt
                                    perspective={1000}
                                        style={{
                                            cursor: "pointer",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "rgba(31, 31, 56, 0.50)",
                                            borderRadius: "8px",
                                            padding: "16px 12px",
                                            color: "#ffffff",
                                            fontSize: "14px",
                                            backdropFilter: "blur(8px)",
                                            gap: "8px",
                                        }}
                                        >
                                        <img src="icons/daimond.svg" alt="" width={12} height={12}/>
                                        Got it, so a mix of performance
                                        </Tilt>
                                </div>
                                <div className="col-12 col-lg-6 mb-3">
                                <Tilt
                                    perspective={1000}
                                        style={{
                                            cursor: "pointer",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "rgba(31, 31, 56, 0.50)",
                                            borderRadius: "8px",
                                            padding: "16px 12px",
                                            color: "#ffffff",
                                            fontSize: "14px",
                                            backdropFilter: "blur(8px)",
                                            gap: "8px",
                                        }}
                                        >
                                        <img src="icons/daimond.svg" alt="" width={12} height={12}/>
                                        Got it, so a mix of performance
                                        </Tilt>
                                </div>
                                <div className="col-12 col-lg-6 mb-3">
                                <Tilt
                                    perspective={1000}
                                        style={{
                                            cursor: "pointer",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "rgba(31, 31, 56, 0.50)",
                                            borderRadius: "8px",
                                            padding: "16px 12px",
                                            color: "#ffffff",
                                            fontSize: "14px",
                                            backdropFilter: "blur(8px)",
                                            gap: "8px",
                                        }}
                                        >
                                        <img src="icons/daimond.svg" alt="" width={12} height={12}/>
                                        Got it, so a mix of performance
                                        </Tilt>
                                </div>
                                <div className="col-12 col-lg-6 mb-3">
                                <Tilt
                                    perspective={1000}
                                        style={{
                                            cursor: "pointer",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "rgba(31, 31, 56, 0.50)",
                                            borderRadius: "8px",
                                            padding: "16px 12px",
                                            color: "#ffffff",
                                            fontSize: "14px",
                                            backdropFilter: "blur(8px)",
                                            gap: "8px",
                                        }}
                                        >
                                        <img src="icons/daimond.svg" alt="" width={12} height={12}/>
                                        Got it, so a mix of performance
                                        </Tilt>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 mb-3">
                            <Button inner="Tygo Business" color=" primary square-btnn px-5 mt-3 btn rounded full-width" onClick={
                                () => {
                                    navigate("/professionals")
                                }
                            } />
                                                            </div>
                                </div>
                    </div>
                    <div className="col-12 col-lg-2 order-3 order-lg-2">
                    </div>

                    <div className="col-12 col-lg-4 order-1 order-lg-3">
                        <div className="pros_section__right my-5">
                            <img src="./media/landing/pros.svg" style={{ width: "100%" }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
